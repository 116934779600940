.wrapper {
  height: 80vh;
  background-size: 100% 100%, contain;
  background-position: center;
  object-fit: contain;
}
.m_wrapper{
  display: none;
  background-size: 100% 100%, contain;
  background-position: center;
  object-fit: contain;
}

.wrapperTxt {
  position: relative;
  top: 150px;
  left: 30px;
}

@media screen and (max-width: 720px) {
  .m_wrapper {
    height: 40vh;
    display: block;
  }
  .wrapper{
    display: none;
  }
  .wrapperTxt {
    top: 90px;
    left: 10px;
  }
}
