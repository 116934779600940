.box {
  height: 420px;
  width: 680px;
  border-radius: 16px;
}

.rightBox {
  color: white;
  border-radius: 12px;
}

.zoom {
  transition: transform 0.2s; /* Animation */
  margin: 20px;
  height: 700px;
  width: 600px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.zoom:hover {
  transform: scale(
    1.1
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
.payment {
  background: linear-gradient(#96cdd4, #e5edfd, #f8f9fa);
  border-radius: 12px;
}

@media screen and (max-width: 800px) {
  .zoom {
    height: 420px;
    width: 280px;
  }
  .box {
    height: 100%;
    width: 280px;
    border-radius: 16px;
  }
}
