@font-face {
  font-family: "Rubik";
  src: local("Rubik"),
    url("./container//material.theme//fonts/Rubik/static/Rubik-Regular.ttf")
      format("truetype");
}
@font-face {
  font-family: "Rubik-Light";
  src: local("Rubik"),
    url("./container//material.theme//fonts/Rubik/static/Rubik-Light.ttf")
      format("truetype");
}
@font-face {
  font-family: "Rubik-SemiBold";
  src: local("Rubik"),
    url("./container//material.theme//fonts/Rubik/static/Rubik-SemiBold.ttf")
      format("truetype");
}
@font-face {
  font-family: "Rubik-Bold";
  src: local("Rubik"),
    url("./container//material.theme//fonts/Rubik/static/Rubik-Bold.ttf")
      format("truetype");
}
@font-face {
  font-family: "Joined-Bold";
  src: local("Joined-Bold"),
    url("./container/material.theme/fonts/Dancing_Script/static/DancingScript-Bold.ttf")
      format("truetype");
}
@font-face {
  font-family: "Comfortaa";
  src: local("Comfortaa"),
    url("./container/material.theme/fonts/Comfortaa/static/Comfortaa-Medium.ttf")
      format("truetype");
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./container/material.theme/fonts/Poppins/Poppins-Medium.ttf")
      format("truetype");
}

//swiper carousel
:root {
  --swiper-navigation-size: 40px !important;
}
.swiper-button-next,
.swiper-button-prev {
  color: #1c3664 !important;
  // box-shadow: 0px 3px 12px #c8cfde99 !important;
  // background: yellow;
  // height: 500px;
  // width: 500px;
  // border-radius: 100%;
  // padding: 10px;
}

//scroll bar
div::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #f3f3f3;
}

div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f3f3f3;
  border-radius: 10px;
}

div::-webkit-scrollbar-thumb {
  background-color: #888787;
  border-radius: 10px;
}
div::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #f3f3f3;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f3f3f3;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: #28242c;
  border-radius: 10px;
}
body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.body {
  background: #f8f9fa;
  min-height: 100vh;
}
.paper-root {
  box-shadow: 0px 3px 12px #c8cfde99 !important;
}
.font-weight-bolder {
  font-weight: bolder;
}
.h-40 {
  height: 40%;
}
.h-60 {
  height: 60%;
}
.h-20 {
  height: 20%;
}
.h-80 {
  height: 80%;
}
.h-60-vh {
  height: 60vh;
}
.h-75-vh {
  height: 75vh;
}
.min-h-100-vh {
  min-height: 100vh;
}
.min-h-75-vh {
  min-height: 75vh;
}
.min-h-50-vh {
  min-height: 50vh;
}
.min-h-25 {
  min-height: 25vh;
}
.w-13 {
  width: 13% !important;
}
.w-15 {
  width: 15% !important;
}
.w-16 {
  width: 16% !important;
}
.w-5 {
  width: 5% !important;
}
.w-10 {
  width: 10% !important;
}
.w-100 {
  width: 100% !important;
}
.w-18 {
  width: 18% !important;
}
.w-30 {
  width: 30% !important;
}
.w-34 {
  width: 34% !important;
}
.w-35 {
  width: 35% !important;
}
.w-25 {
  width: 25% !important;
}
.w-36 {
  width: 36% !important;
}
.w-37 {
  width: 37% !important;
}
.w-20 {
  width: 20% !important;
}
.w-13 {
  width: 13% !important;
}
.w-33 {
  width: 33% !important;
}
.w-40 {
  width: 40% !important;
}
.w-45 {
  width: 45% !important;
}
.w-48 {
  width: 48% !important;
}
.w-49 {
  width: 49% !important;
}
.w-55 {
  width: 55% !important;
}
.w-70 {
  width: 70% !important;
}
.w-65 {
  width: 65% !important;
}
.w-60 {
  width: 60% !important;
}
.w-87 {
  width: 87% !important;
}
.w-85 {
  width: 85% !important;
}
.w-80 {
  width: 80% !important;
}
.w-83 {
  width: 83% !important;
}
.w-90 {
  width: 90% !important;
}
.w-95 {
  width: 95% !important;
}
.bg-black {
  background-color: #000000;
}
.border-radius-100 {
  border-radius: 50% !important;
}
.z-index {
  z-index: 1;
}
.z-index-n {
  z-index: -1;
}
.z-index-2 {
  z-index: 2;
}
.z-index-100 {
  z-index: 100;
}
.z-index-1000 {
  z-index: 1000 !important;
}
.z-index-1200 {
  z-index: 1200 !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
*:focus {
  outline: none !important;
}
.width-max-content {
  width: max-content;
}

.text-ellipsis-clamp-1 {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  // height: 30px;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.text-ellipsis-clamp-2 {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.text-ellipsis-clamp-3 {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.overflow-hidden {
  overflow: hidden !important;
}
.text-transform-none {
  text-transform: none !important;
}
.heading {
  letter-spacing: 10px;
  // font-family: 'Poppins' !important;
  // font-weight: 700 !important;
}
.shaded {
  letter-spacing: 10px;
  position: relative;
  top: 35px;
  // font-family: 'Poppins' !important;
}

@media screen and (max-width: 600px) {
  .heading {
    letter-spacing: 5px;
  }
  .shaded {
    letter-spacing: 5px;
    position: relative;
    top: 22px;
    // font-family: 'Poppins' !important;
  }
}

@media screen and (max-width: 450px) {
  .shaded {
    display: none;
  }
}
